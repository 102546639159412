import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";

const SectionMainTitle = styled.h1`
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 50px;
    line-height: 62px;
  }

  @media ${device.lg} {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 30px;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const HeroMainTitle = styled(SectionMainTitle)`
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 76px;
    line-height: 84px;
  }

  @media ${device.xl} {
    font-size: 80px;
    line-height: 84px;
  }
`;

const MainTitle = ({ variant, ...rest }) => {
    let MainTitleStyled = SectionMainTitle;

    switch (variant) {
        case "hero":
            MainTitleStyled = HeroMainTitle;
            break;
        default:
            MainTitleStyled = SectionMainTitle;
    }

    return <MainTitleStyled color="heading" {...rest} />;
};

export default MainTitle;
