import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/svg/netomnia-logo.svg";
import imgL1LogoWhite from "../../assets/image/png/logo-white.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img alt="" src={imgL1LogoWhite}  />
      ) : (
        <img alt="" src={imgL1Logo} width={190} height={31}/>
      )}
    </Link>
  );
};

export default Logo;
