import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  space,
  border,
  typography,
  shadow,
  flexbox,
  layout,
} from "styled-system";
import { retrieveOtherVoiceAddon } from "../../utils/OdinPricingHelpers";

/* BIG SWITCH ENERGY */
const BigSwitchBody = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 90px;
  height: 40px;
  border-radius: 30px;
  transition: all 0.3s ease-out;
  background: ${({ active, theme, bg }) =>
    active ? 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)' : `#eae9f2`};
  position: relative;
  margin: 0 15px;
  white-space: nowrap;
  box-shadow:       inset 0px -4px 6px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0px -4px 6px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow:    inset 0px -4px 6px -2px rgba(0, 0, 0, 0.2);
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`;
const BigSwitchToggle = styled.span`
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  position: absolute;
  transition: all 0.3s ease-out;
  left: ${({ active }) => (active ? `calc(100% - 41px)` : `0`)};
  margin-left: 8px;
  top: 0;
  margin-top: 7px;
  transition: 0.4s;
  border-radius: 500px;
  pointer-events: none;
  -webkit-box-shadow: -2px -1px 8px 0px rgba(0,0,0,0.27);
  -moz-box-shadow: -2px -1px 8px 0px rgba(0,0,0,0.27);
  box-shadow: -2px -1px 8px 0px rgba(0,0,0,0.27);
`;

/* SMALL SWITCH ENERGY */
const SmallSwitchBody = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 70px;
  height: 30px;
  border-radius: 30px;
  transition: all 0.3s ease-out;
  background: ${({ active, theme, bg }) =>
    active ? 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)' : `#eae9f2`};
  position: relative;
  margin: 0 15px;
  white-space: nowrap;
  box-shadow:       inset 0px -4px 6px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0px -4px 6px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow:    inset 0px -4px 6px -2px rgba(0, 0, 0, 0.2);
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`;
const SmallSwitchToggle = styled.span`
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  position: absolute;
  transition: all 0.3s ease-out;
  left: ${({ active }) => (active ? `calc(100% - 35px)` : `0`)};
  margin-left:6px;
  top: 0;
  margin-top: 4px;
  transition: 0.4s;
  border-radius: 500px;
  pointer-events: none;
  -webkit-box-shadow: -2px -1px 8px 0px rgba(0,0,0,0.27);
  -moz-box-shadow: -2px -1px 8px 0px rgba(0,0,0,0.27);
  box-shadow: -2px -1px 8px 0px rgba(0,0,0,0.27);
`;


const AgreementSwitch = ({ bg = "secondary", onClick = () => { }, setFieldValue, ...rest }) => {
  const [active, setActive] = useState(false);
  const handleSwitch = () => {
    setActive(!active);
    setFieldValue("agreementSwitch", !active)
    onClick();
  };

  return (
    <BigSwitchBody
      bg={bg}
      {...rest}
      onClick={handleSwitch}
      value={active ? 1 : 0}
      active={active ? 1 : 0}
    >
      <BigSwitchToggle active={active ? 1 : 0} />
    </BigSwitchBody>
  );
};

const BusinessSwitch = ({ bg = "secondary", onClick = () => { }, setFieldValue, ...rest }) => {
  const [active, setActive] = useState(false);
  const handleSwitch = () => {
    setActive(!active);
    setFieldValue("businessSwitch", !active)
    onClick();
  };

  return (
    <BigSwitchBody
      bg={bg}
      {...rest}
      onClick={handleSwitch}
      value={active ? 1 : 0}
      active={active ? 1 : 0}
    >
      <BigSwitchToggle active={active ? 1 : 0} />
    </BigSwitchBody>
  );
};

const TinySwitch = ({ bg = 'secondary', onClick = () => { }, setValue, value = false, ...rest }) => {
  const [active, setActive] = useState(false);
  const handleSwitch = () => {
    setActive(!active);
    setValue(!active);
    onClick();
  };

  useEffect(() => {
    setActive(value)
  }, [value]);

  return (
    <SmallSwitchBody
      bg={bg}
      {...rest}
      onClick={handleSwitch}
      value={active ? 1 : 0}
      active={active ? 1 : 0}
    >
      <SmallSwitchToggle active={active ? 1 : 0} />
    </SmallSwitchBody>
  );
};

const MarketingConsent = ({ bg = "secondary", onClick = () => { }, setFieldValue, ...rest }) => {
  const [active, setActive] = useState(false);
  const handleSwitch = () => {
    setActive(!active);
    setFieldValue("marketingConsent", !active)
    onClick();
  };

  return (
    <BigSwitchBody
      bg={bg}
      {...rest}
      onClick={handleSwitch}
      value={active ? 1 : 0}
      active={active ? 1 : 0}
    >
      <BigSwitchToggle active={active ? 1 : 0} />
    </BigSwitchBody>
  );
};

const AddonSwitch = ({
  bg = "secondary",
  onClick = () => { },
  switchName,
  disabled,
  orderConfigurationProperties,
  setOrderConfigurationProperties,
  setFieldValue,
  ...rest }) => {

  const handleSwitch = () => {

    if (switchName === "TCSwitch") {
      setFieldValue('TCSwitch', !orderConfigurationProperties.TCSwitchState)
      setOrderConfigurationProperties({
        ...orderConfigurationProperties,
        TCSwitchState: !orderConfigurationProperties.TCSwitchState
      })

    }
    else if (switchName === "LOASwitch") {
      setFieldValue('LOASwitch', !orderConfigurationProperties.LOASwitchState)
      setOrderConfigurationProperties({
        ...orderConfigurationProperties,
        LOASwitchState: !orderConfigurationProperties.LOASwitchState
      })

    }
    else {

      let allAddons = orderConfigurationProperties.selectedAddons;

      /* No addons present at all */
      if (allAddons.length < 1)
        allAddons.push(switchName)

      /* Some addons present, filter and remove/add what is necessary. */
      else {
        if (allAddons.indexOf(switchName) > -1) {
          allAddons = allAddons.filter(a => a !== switchName)
        }
        else {
          /* If this addon is VOICE and there is other one active -> remove the other one */
          allAddons = allAddons.filter(a => a !== retrieveOtherVoiceAddon(orderConfigurationProperties.rawOffer, switchName))
          allAddons.push(switchName)
        }
      }

      setOrderConfigurationProperties({
        ...orderConfigurationProperties,
        selectedAddons: allAddons.length > 0 ? allAddons : []
      })
    }

  };

  const getSwitchStateByName = (switchName) => {
    if (switchName === "TCSwitch")
      return orderConfigurationProperties.TCSwitchState
    else if (switchName === "LOASwitch")
      return orderConfigurationProperties.LOASwitchState
    else
      return orderConfigurationProperties.selectedAddons.find(addon =>
        addon === switchName ? 1 : 0
      )
  }


  return (
    <SmallSwitchBody
      bg={bg}
      onClick={!disabled ? handleSwitch : () => { }}
      value={getSwitchStateByName(switchName)}
      active={getSwitchStateByName(switchName)}
      {...rest}
    >
      <SmallSwitchToggle active={getSwitchStateByName(switchName)} />
    </SmallSwitchBody>
  );
};

export { AgreementSwitch, BusinessSwitch, TinySwitch, AddonSwitch, MarketingConsent }
