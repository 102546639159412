import React, { useRef, useState } from "react";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
    const [themeDark, setThemeDark] = useState(false);
    const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
    const [headerDark, setHeaderDark] = useState(false);
    const [footerDark, setFooterDark] = useState(true);
    const [cartTotal, setCartTotal] = useState(3);
    const [fullScreenLoader, SetFullScreenLoader] = useState(false)
    const postcodeRef = useRef(null);
    const [registrationData, setRegistrationData] = useState(null);
    const [errorModalVisible, setErrorModalVisible] = useState(false);

    const toggleTheme = () => {
        setThemeDark(!themeDark);
    };

    const toggleOffCanvas = () => {
        setVisibleOffCanvas(!visibleOffCanvas);
    };

    const closeOffCanvas = () => {
        setVisibleOffCanvas(false);
    };

    const goHeaderDark = () => {
        setHeaderDark(true);
    };
    const goHeaderLight = () => {
        setHeaderDark(false);
    };

    const goFooterDark = () => {
        setFooterDark(true);
    };
    const goFooterLight = () => {
        setFooterDark(false);
    };

    const incCartTotal = () => {
        setCartTotal(cartTotal + 1);
    };
    const decCartTotal = () => {
        setCartTotal(cartTotal - 1);
    };

    const showFullScreenLoader = () => {
        if (typeof window !== 'undefined' && window) {
            SetFullScreenLoader(true)
            document.body.classList.add('modal-open')
        }
    }

    const hideFullScreenLoader = () => {
        if (typeof window !== 'undefined' && window) {
            SetFullScreenLoader(false)
            document.body.classList.remove('modal-open')
        }
    }

    const toggleErrorModal = () => {
        setErrorModalVisible(!errorModalVisible);
    };

    const goSetRegistrationData = (registrationData) => {
        setRegistrationData(registrationData)
        if (typeof window !== 'undefined' && window) {
            localStorage.setItem('registrationData', JSON.stringify(registrationData));
        }
    }

    const goResetRegistrationData = () => {
        goSetRegistrationData({
            searchPremiseResults: undefined,
            bduk: undefined,
            personalData: undefined,
            currentProvider: undefined,
            currentContractType: undefined,
            addressId: undefined,
            registerStep: undefined,
            LOAAuthorized: undefined,
            portFromProvider: undefined,
        })
    }

    const goGetRegistrationData = () => {
        if (registrationData) {
            return registrationData
        } else if (typeof window !== 'undefined' && window && localStorage.getItem('registrationData')) {
            var regData = localStorage.getItem('registrationData')
            if (regData && regData !== "undefined") {
                return JSON.parse(regData)
            }
        } else {
            return undefined
        }
    }

    return (
        <GlobalContext.Provider
            value={{
                themeDark,
                toggleTheme,
                headerDark,
                goHeaderDark,
                goHeaderLight,
                footerDark,
                goFooterDark,
                goFooterLight,
                visibleOffCanvas,
                toggleOffCanvas,
                closeOffCanvas,
                cartTotal,
                incCartTotal,
                decCartTotal,
                postcodeRef,
                registrationData,
                setRegistrationData,
                showFullScreenLoader,
                hideFullScreenLoader,
                fullScreenLoader,
                errorModalVisible,
                setErrorModalVisible,
                toggleErrorModal,
                goSetRegistrationData,
                goGetRegistrationData,
                goResetRegistrationData
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
export { GlobalProvider };
