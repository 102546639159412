import React from "react";
import styled from "styled-components";
import {
  color,
  background,
  space,
  border,
  typography,
  shadow,
  flexbox,
  layout,
} from "styled-system";

const ButtonSolid = styled.button`
  padding: 0.85rem 1.75rem;
  min-width: 200px;
  border-radius: 5px;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
  outline: none !important;
  word-break: break-word;
  white-space: break-spaces;
  ${color};
  ${background};
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
`;

const ButtonSmall = styled(ButtonSolid)`
  min-width: auto;
  padding: 0.35rem 1.3rem;
  font-size: 17px;
  `;

const ButtonWhite = styled(ButtonSolid)`
  border-radius: 100px;
  background-color: #ffffff;
  font-size: 28px;
  padding: 0.8rem 1.8rem;
  &:before {
    background: rgba(252,58,76, 0.1);
  }
  &:hover:before,
  &:focus:before,
  &:active:before {
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
`;

const Button = ({
  variant = "solid",
  color = "light",
  bg = "primary",
  ...rest
}) => {
  switch(variant){
    case 'solid': return <ButtonSolid
      color={color}
      border={`none`}
      borderColor={bg}
      style={{background:'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)'}}
      {...rest}
    />;
    case 'white': return <ButtonWhite
    color={color}
    border={`none`}
    borderColor={bg}
    style={{background:'linear-gradient(90deg, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,1) 100%)'}}
    {...rest}
  />;
    default: return <ButtonSmall
      color={color}
      style={{background:'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)'}}
      border={`1px solid`}
      borderColor={color}
      {...rest}
    />;
  };
};

export default Button;
