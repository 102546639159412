import {OdinProductSchema} from "./OdinProductSchema";
import {getProperty} from "./helperFn";

export const getPrice = (product) => {
    if (product.DiscountValue > 0) {
        let discountedPrice;

        if (product.DiscountType === 'AMOUNT') {
            discountedPrice = Number(product.UnitPrice) - Number(product.DiscountValue)
        } else {
            discountedPrice = Number(product.UnitPrice) - Number(product.UnitPrice) * (Number(product.DiscountValue) / 100)
        }

        return discountedPrice;

    } else {
        return Number(product.UnitPrice)
    }
}

export const RetrieveFilteredProductsFromOffer = ({
                                                      rawOffer = false,
                                                      productCategory = false,
                                                      contractType = false
                                                  }) => {

    const allowedAddons = ['YouPhone', 'YouMesh', 'Static IP']

    let allAddons = rawOffer.dbRecords.filter(product =>
        product.properties.Type === 'ADD_ON_PRODUCT' &&
        allowedAddons.includes(product.properties.DisplayName)
    )

    /* YouFibre guys wanted addons in following order: 1. YouMesh, 2. YouPhone, 3. Static IP */
    allAddons.sort(function (a, b) {
        if (a.properties.DisplayName === 'YouMesh')
            return -1
        else if (a.properties.DisplayName === 'YouPhone')
            return -1
        else
            return -1
    })

    /* Filter Base products on Category & ContractType */
    const filteredProducts = rawOffer.dbRecords.filter(product =>
        product.properties.Category === productCategory &&
        product.properties.ContractType === contractType &&
        product.properties.Type !== 'ADD_ON_PRODUCT'
    )

    /* Inject all addons */
    filteredProducts.map(product =>
        product.properties.AvailableAddons = allAddons
    )


    /* Sort products on UnitPrice */
    return filteredProducts.sort(function (a, b) {
        return parseFloat(a.properties.UnitPrice) - parseFloat(b.properties.UnitPrice)
    });

}

export const RetrieveAllProductsFromOffer = (rawOffer) => {
    return rawOffer.dbRecords.filter(product =>
        product.properties.Type === 'BASE_PRODUCT'
    )
}

export const RetrieveAllProductsForSelect = (rawOffer) => {
    return rawOffer.dbRecords.filter(product =>
        product.properties.Type === 'BASE_PRODUCT'
    ).map(product => ({
        label: product.properties.ContractType === 'MONTHLY'
            ? product.properties.DisplayName.replace('<br>', '') + ' (Monthly)'
            : product.properties.DisplayName.replace('<br>', '') + ' (' + product.properties.ContractType.split('_')[1] + ' months)',
        value: product.id
    }));

}

export const RetrieveProductById = (rawOffer, productId) => {

    if (rawOffer && productId)
        return rawOffer.dbRecords.find(product =>
            product.id === productId
        )
}

export const RetrieveProductTitleById = (rawOffer, ProductId) => {
    return rawOffer.dbRecords.filter(product =>
        product.id === ProductId
    ).map(product => (
            product.properties.ContractType === 'MONTHLY'
                ? product.properties.DisplayName.replace('<br>', '') + ' (Monthly)'
                : product.properties.DisplayName.replace('<br>', '') + ' (' + product.properties.ContractType.split('_')[1] + ' months)'
        )
    )[0]
}


export const RetrieveDefaultProduct = (rawOffer) => {
    return rawOffer.dbRecords.find(product => product.properties.Default === "true")
}


export const RetrieveAllAddonsForProduct = (rawOffer, selectedProduct) => {

    const Product = rawOffer.dbRecords.filter(product =>
        product.id === selectedProduct
    )

    if (Product[0].properties.Category === 'BROADBAND')
        return rawOffer.dbRecords.filter(Addon => {
            if (Product[0].id == OdinProductSchema.specificProductId) {
                // Return all add ons except default you mesh.
                return Addon.properties.Type === 'ADD_ON_PRODUCT' && Addon.id !== OdinProductSchema.defaultYouMeshID
            } else {
                // Return all add ons except free you mesh.
                return Addon.properties.Type === 'ADD_ON_PRODUCT' && Addon.id !== OdinProductSchema.freeYouMeshID
            }
        })
     else
        return {}


}

/* Subtract base product title and price from the offer. */
export const RetrieveBaseProductTitleAndPrice = (rawOffer, selectedProduct) => {
    const getDiscountedPrice = (Product) => {

        /* DiscountType = AMOUNT */
        if (Product.DiscountType === "AMOUNT") {
            return parseFloat(Product.UnitPrice) - parseFloat(Product.DiscountValue)
        }
        /* DiscountType = PERCENT */
        else if (Product.DiscountType === "PERCENT") {
            return parseFloat(Product.UnitPrice) - parseFloat(Product.UnitPrice) * (parseFloat(Product.DiscountValue) / 100)
        }
        /* Sometimes discount indicators can be null... */
        else {
            return parseFloat(Product.UnitPrice)
        }
    }

    const constructProductTitle = (product) => {
        if (product.properties.ContractType === 'MONTHLY') {
            return `${product.properties.DisplayName} (Monthly)`
        } else {
            return `${product.properties.DisplayName} (${product.properties.ContractType.split('_')[1]} months)`
        }
    }

    /* Find the product in the offer and get the correct price*/
    const Product = rawOffer.dbRecords.find(product =>
        product.id === selectedProduct
    )

    if (Product.properties.DiscountValue !== "0.00") {
        return {
            ProductTitle: constructProductTitle(Product),
            ProductPrice: getDiscountedPrice(Product.properties)
        }
    } else {
        return {
            ProductTitle: constructProductTitle(Product),
            ProductPrice: Product.properties.UnitPrice
        }
    }
}

/* Subtract addons titles and prices from the offer. */
export const RetrieveAddonsTitlesAndPrices = (rawOffer, selectedAddons) => {
    return rawOffer.dbRecords.filter(
        element => selectedAddons.includes(element.id)
    ).map(
        addon => ({
            AddonTitle: addon.properties.DisplayName,
            AddonPrice: addon.properties.UnitPrice,
            AddonDiscountType: addon.properties.DiscountType,
            AddonDiscountValue: addon.properties.DiscountValue
        })
    );
}

/* Get subtotal price but allow for calculation w/o discounted prices. */
export const CalculateSubtotalPrice = ({
                                           rawOffer = {},
                                           selectedBaseProduct = '',
                                           selectedAddons = '',
                                           useDiscountedPrices = false
                                       }) => {
    let subtotalPrice = 0;

    rawOffer.dbRecords.map(product => {
            if (product.id === selectedBaseProduct || selectedAddons.indexOf(product.id) > -1) {
                if (useDiscountedPrices)
                    subtotalPrice += getPrice(product.properties)
                else
                    subtotalPrice += parseFloat(product.properties.UnitPrice)
            }
        }
    )
    return subtotalPrice
}

export const CalculateTotalPrice = ({
                                        subTotalPrice = 0,
                                        salesStatus = '',
                                        couponDiscount = {},
                                        rawOffer,
                                        selectedProduct
                                    }) => {

    const product = RetrieveProductById(rawOffer, selectedProduct)

    /*
    if (salesStatus === 'pre_order'
        && getProperty(couponDiscount, 'DiscountValue') === 0
        && product.properties.ContractType !== 'MONTHLY'
    ) {
        return subTotalPrice - calculateDiscount({
            SubTotalPrice: subTotalPrice,
            DiscountType: 'PERCENT',
            DiscountValue: 10
        })
    }
    */

    if (getProperty(couponDiscount, 'DiscountValue') > 0) {
        return subTotalPrice - calculateDiscount({
            SubTotalPrice: subTotalPrice,
            DiscountType: getProperty(couponDiscount, 'DiscountType'),
            DiscountValue: getProperty(couponDiscount, 'DiscountValue'),
        })
    } else {
        return subTotalPrice
    }

}

/* Construct legal text that will be rendered above Checkout summary. */
export const ConstructLegalTextForPaymentSummary = (rawOffer, selectedProduct, subtotalPrice, totalPrice, couponDiscount, rawSubtotal) => {

    let legalText = {fullText: '', freeMonths: ''}

    const Product = rawOffer.dbRecords.find(product =>
        product.id === selectedProduct
    )


    const ContractType = Product.properties.ContractType.split('_');

    if (Product.properties.TrialLength && Product.properties.TrialLength > 0) {
        legalText.freeMonths = Product.properties.TrialLength > 1 ? `First ${Product.properties.TrialLength} months free!` : `First month free!`;
    } else {
        legalText.freeMonths = ''
    }

    if (
        Product.properties.CustomerType === 'RESIDENTIAL' &&
        Product.properties.DisplayName === "YouFibre 150" &&
        Product.properties.ContractType === "ANNUAL_24"
    ) {
        if (couponDiscount.DiscountUnit !== '' && couponDiscount.DiscountLength !== '') {
            legalText.fullText = `\xA3${totalPrice.toFixed(2)} a month for 12 months, then \xA3${subtotalPrice.toFixed(2)} for 12 months + coupon discount`;
        } else {
            legalText.fullText = `\xA3${totalPrice.toFixed(2)} a month for 12 months, then \xA3${subtotalPrice.toFixed(2)} for 12 months`;
        }

    } else {
        // With coupon
        if (couponDiscount.DiscountUnit !== '' && couponDiscount.DiscountLength !== '') {
            // Annual contract with coupon
            if (ContractType[0] === "ANNUAL") {
                // Annual contract with coupon when discount lasts more than the trial period.
                if (parseFloat(couponDiscount.DiscountLength) >= parseFloat(ContractType[1])) {
                    legalText.fullText = `\xA3${parseFloat(totalPrice.toFixed(2)) >= 0 ? totalPrice.toFixed(2) : 0} a month for ${couponDiscount.DiscountLength} months, then \xA3${subtotalPrice.toFixed(2)} a month. `;
                } else {
                    // Annual contract with coupon when discount lasts less than the trial period.
                    legalText.fullText = `\xA3${parseFloat(totalPrice.toFixed(2)) >= 0 ? totalPrice.toFixed(2) : 0} a month for ${couponDiscount.DiscountLength} months, then \xA3${rawSubtotal} a month for ${ContractType[1] - parseInt(couponDiscount.DiscountLength)} months, then \xA3${subtotalPrice.toFixed(2)} a month. `;
                }
                // Monthly contract with coupon
            } else {
                legalText.fullText = `\xA3${parseFloat(totalPrice.toFixed(2)) >= 0 ? totalPrice.toFixed(2) : 0} a month for ${couponDiscount.DiscountLength} months than \xA3${rawSubtotal} a month and no commitment, just 30 days' notice. `;
            }
            // Without coupon
        } else {
            // Annual contract without coupon
            if (ContractType[0] === "ANNUAL") {
                legalText.fullText = `\xA3${totalPrice.toFixed(2)} a month for ${ContractType[1]} months, then \xA3${subtotalPrice.toFixed(2)} a month. `;
                // Monthly contract without coupon
            } else {
                legalText.fullText = `\xA3${totalPrice.toFixed(2)} a month and no commitment, just 30 days' notice. `;
            }
        }
    }


    return legalText
}

/* Sometimes we have two VOICE addons, but user can't have both. If user selects one, deselect the other. */
export const retrieveOtherVoiceAddon = (rawOffer, addonId) => {
    let voiceAddon = rawOffer.dbRecords.find(product =>
        product.id === addonId
    ).properties.Category

    if (voiceAddon === "VOICE")
        return rawOffer.dbRecords.find(product =>
            product.id !== addonId && product.properties.Category === "VOICE" && product.properties.Type === "ADD_ON_PRODUCT"
        ).id
    else
        return {}
}

export const checkIfVoiceProductIsPresent = (rawOffer, productId, addonIds) => {
    return rawOffer.dbRecords.some(product =>
        (product.id === productId && product.properties.Category === 'VOICE') ||
        (addonIds.includes(product.id) && product.properties.Category === 'VOICE')
    )
}

export const calculateDiscount = ({SubTotalPrice = 0, DiscountType = '', DiscountValue = ''}) => {
    let DiscountAmount = 0

    if (DiscountType === 'PERCENT') {
        DiscountAmount = (SubTotalPrice * (DiscountValue / 100))
    } else {
        DiscountAmount = DiscountValue
    }

    return DiscountAmount
}

export const calculateAddonPrice = (addon) => {
    let price = parseFloat(addon.AddonPrice);
    if (addon.AddonDiscountType && addon.AddonDiscountType === 'PERCENT') {
        price = price - ((parseFloat(addon.AddonDiscountValue) / 100) * parseFloat(addon.AddonPrice));
    }
    if (addon.AddonDiscountType && addon.AddonDiscountType === 'AMOUNT') {
        price = price - parseFloat(addon.AddonDiscountValue);
    }
    return price.toFixed(2);
}