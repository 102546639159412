// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-areas-index-js": () => import("./../../../src/pages/areas/index.js" /* webpackChunkName: "component---src-pages-areas-index-js" */),
  "component---src-pages-areas-strapi-regions-slug-js": () => import("./../../../src/pages/areas/{strapiRegions.Slug}.js" /* webpackChunkName: "component---src-pages-areas-strapi-regions-slug-js" */),
  "component---src-pages-broadband-js": () => import("./../../../src/pages/broadband.js" /* webpackChunkName: "component---src-pages-broadband-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-check-availability-js": () => import("./../../../src/pages/check-availability.js" /* webpackChunkName: "component---src-pages-check-availability-js" */),
  "component---src-pages-cities-local-stats-js": () => import("./../../../src/pages/cities/LocalStats.js" /* webpackChunkName: "component---src-pages-cities-local-stats-js" */),
  "component---src-pages-cities-strapi-towns-slug-js": () => import("./../../../src/pages/cities/{strapiTowns.Slug}.js" /* webpackChunkName: "component---src-pages-cities-strapi-towns-slug-js" */),
  "component---src-pages-commercial-wayleaves-js": () => import("./../../../src/pages/commercial-wayleaves.js" /* webpackChunkName: "component---src-pages-commercial-wayleaves-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diversions-js": () => import("./../../../src/pages/diversions.js" /* webpackChunkName: "component---src-pages-diversions-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gigabit-voucher-scheme-js": () => import("./../../../src/pages/gigabit-voucher-scheme.js" /* webpackChunkName: "component---src-pages-gigabit-voucher-scheme-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-insights-strapi-insights-slug-js": () => import("./../../../src/pages/insights/{strapiInsights.slug}.js" /* webpackChunkName: "component---src-pages-insights-strapi-insights-slug-js" */),
  "component---src-pages-jobs-strapi-jobs-slug-js": () => import("./../../../src/pages/jobs/{strapiJobs.slug}.js" /* webpackChunkName: "component---src-pages-jobs-strapi-jobs-slug-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-strapi-legal-article-article-slug-js": () => import("./../../../src/pages/legal/{StrapiLegalArticle.article_slug}.js" /* webpackChunkName: "component---src-pages-legal-strapi-legal-article-article-slug-js" */),
  "component---src-pages-network-js": () => import("./../../../src/pages/network.js" /* webpackChunkName: "component---src-pages-network-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-strapi-news-articles-slug-js": () => import("./../../../src/pages/news/{strapiNewsArticles.slug}.js" /* webpackChunkName: "component---src-pages-news-strapi-news-articles-slug-js" */),
  "component---src-pages-pia-js": () => import("./../../../src/pages/pia.js" /* webpackChunkName: "component---src-pages-pia-js" */),
  "component---src-pages-planning-js": () => import("./../../../src/pages/planning.js" /* webpackChunkName: "component---src-pages-planning-js" */),
  "component---src-pages-private-roads-js": () => import("./../../../src/pages/private-roads.js" /* webpackChunkName: "component---src-pages-private-roads-js" */),
  "component---src-pages-register-bduk-tsx": () => import("./../../../src/pages/register-bduk.tsx" /* webpackChunkName: "component---src-pages-register-bduk-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-rollout-js": () => import("./../../../src/pages/rollout.js" /* webpackChunkName: "component---src-pages-rollout-js" */),
  "component---src-pages-scottish-voucher-scheme-js": () => import("./../../../src/pages/scottish-voucher-scheme.js" /* webpackChunkName: "component---src-pages-scottish-voucher-scheme-js" */),
  "component---src-pages-select-provider-js": () => import("./../../../src/pages/select-provider.js" /* webpackChunkName: "component---src-pages-select-provider-js" */),
  "component---src-pages-service-delivery-js": () => import("./../../../src/pages/service-delivery.js" /* webpackChunkName: "component---src-pages-service-delivery-js" */),
  "component---src-pages-success-bduk-js": () => import("./../../../src/pages/success-bduk.js" /* webpackChunkName: "component---src-pages-success-bduk-js" */),
  "component---src-pages-success-generic-index-js": () => import("./../../../src/pages/success-generic/index.js" /* webpackChunkName: "component---src-pages-success-generic-index-js" */),
  "component---src-pages-success-generic-pricing-box-js": () => import("./../../../src/pages/success-generic/pricing-box.js" /* webpackChunkName: "component---src-pages-success-generic-pricing-box-js" */),
  "component---src-pages-success-generic-progress-bar-js": () => import("./../../../src/pages/success-generic/progress-bar.js" /* webpackChunkName: "component---src-pages-success-generic-progress-bar-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-wayleaves-for-landlords-js": () => import("./../../../src/pages/wayleaves-for-landlords.js" /* webpackChunkName: "component---src-pages-wayleaves-for-landlords-js" */),
  "component---src-pages-wayleaves-for-tenants-js": () => import("./../../../src/pages/wayleaves-for-tenants.js" /* webpackChunkName: "component---src-pages-wayleaves-for-tenants-js" */),
  "component---src-pages-wayleaves-js": () => import("./../../../src/pages/wayleaves.js" /* webpackChunkName: "component---src-pages-wayleaves-js" */)
}

