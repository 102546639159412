import React from "react";

export const titleCase = str => {
  let splitStr = str.toLowerCase().split(" ")
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(" ")
};

export const sanitizeTextForLineBreaks = (text) => {
  if (text.indexOf('\n') !== -1)
    return text.split('\n').map((item, key) => (
      <span key={key}>{item}<br /></span>
    ))
  else
    return <span>{text}</span>
}

export const thousands_separators = (num) => {
  if (num) {
    let num_parts = num.toString().split(".")
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return num_parts.join(".")
  }
}


export const getProperty = (object, propKey) => {
  if (object) {
    return object[propKey]
  }
}

export const getValueByKey = (arr, propName, propValue) => {
  return arr.find(i => i[propName] === propValue);
}

export const emailRegex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,8}$/i;

export const groupBy = (arr, criteria) => {
  const newObj = arr.reduce((acc, currentValue) => {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
}

/**
 * It reorganizes indexed array and returns associative array based on the given key.
 * @param {array} array 
 * @param {string} keyName 
 */
export const getAssociativeArray = (array, keyName) => {
  const tempArr = [];
  array.forEach(element => {
    tempArr[element[keyName]] = element;
  });
  return tempArr;
}

/**
 * It checks existence of UTM params.
 * @param {object} location 
 * @returns object
 */
export const findUtmParams = (location) => {
  const params = new URLSearchParams(location);

  // Medium
  const utmMedium = params.get('utm_medium');
  // Source
  const utmSource = params.get('utm_source');
  // Name
  const utmCampaign = params.get('utm_campaign');
  // Content
  const utmContent = params.get('utm_content');
  // Term
  const utmTerm = params.get('utm_term');

  if (
    (utmMedium !== undefined && utmMedium !== null) ||
    (utmSource !== undefined && utmSource !== null) ||
    (utmCampaign !== undefined && utmCampaign !== null) ||
    (utmContent !== undefined && utmContent !== null) ||
    (utmTerm !== undefined && utmTerm !== null)
  ) {
    return {
      utmMedium,
      utmSource,
      utmCampaign,
      utmContent,
      utmTerm
    };
  } else {
    return false;
  }
}

export const updateQueryParam = (paramName, paramValue) => {
  if (typeof window !== undefined) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(paramName, paramValue);
    window.history.pushState({ query: queryParams.toString() }, '', `?${queryParams.toString()}`);
  }
};