export const menuItems = [
  {
    name: "#",
    label: "How we build",
    items: [
      { name: "planning", label: "Planning" },
      { name: "pia", label: "PIA" },
      { name: "service-delivery", label: "Service delivery" }
    ]
  },
  {
    name: "#",
    label: "Network",
    items: [
      { name: "broadband", label: "Broadband" },
      { name: "areas", label: "Areas" },
      { name: "rollout", label: "Rollout" },
      {
        name:"#",
        label: "Wayleaves",
        items: [
          { name: "wayleaves", label: "Overview" },
          { name: "wayleaves-for-tenants", label: "Wayleaves for tenants" },
          { name: "wayleaves-for-landlords", label: "Wayleaves for landlords" },
          { name: "private-roads", label: "Private Roads" },
          { name: "commercial-wayleaves", label: "Wayleaves for Businesses" }
        ]
      }
    ]
  },
  {
    name: "#",
    label: "About us",
    items: [
      { name: "news", label: "News" },
      { name: "insights", label: "Insights" },
      { name: "about-us", label: "About us" },
      { name: "careers", label: "Careers" },
      { name: "team", label: "Team" }
    ]
  },
  {
    name: "#",
    label: "Funding",
    items: [
      { name: "gigabit-voucher-scheme", label: "Voucher Scheme" },
      { name: "scottish-voucher-scheme", label: "Scotland R100" },
    ]
  },
  {
    name: "help",
    label: "Help",
    items: [
      { name: "contact", label: "Contact" },
      { name: "faqs", label: "FAQs" },
      { name: "diversions", label: "Diversions" },
    ]
  }
];
